import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Container, Row, Col } from 'react-bootstrap';
import ImageComponent from '../Image/ImageComponent';

const Results = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="results">
      <Container className="h-100">
        <Row className="results-wrapper">
          <Col lg={5} sm={12} className="offset-lg-1 order-lg-2 mb-5">
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <ImageComponent filename="process.png" alt="About Dr gio" className="results-image" />
            </Fade>
          </Col>
          <Col lg={6} sm={12} className="order-lg-1">
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={400} distance="30px">
              <div className="results-wrapper__info">
                <p className="results-wrapper__info-text">
                  The psychology that Dr. Gio applies is uniquely designed for very high functioning
                  people. By combining various schools of thought, his psychology is perfect for any
                  domain where motivation and precision matter while trying to achieve results
                  (Sales, Finance, Sports, Leadership).
                </p>
              </div>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={700} distance="30px">
              <ul className="results-list my-2">
                <li>Accuracy (the removal of cognitive biases).</li>
                <li>Self-understanding (so you can lead others).</li>
                <li>Routines (Great Processes drive great results).</li>
                <li>Communication (The key to great cultures is great communication).</li>
                <li>Results (Everything he teaches is designed to aggregate into results).</li>
              </ul>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="results-wrapper__info">
                <p className="results-wrapper__info-text">
                  The results are undeniable. His playbook works in Golf, Sports, Finance, Sales,
                  and Organizational Leadership.
                </p>
              </div>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1300} distance="30px">
              <AniLink cover direction="up" to="/contact" bg="#3486fb">
                <span className="cta-btn">BOOK NOW</span>
              </AniLink>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Results;
