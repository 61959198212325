import React from 'react';
import Layout from '../components/Layout';
import Results from '../components/Results';

function ProcessPage() {
  return (
    <Layout
      title="Dr. Gio Valiante | Process"
      description="Dr. Gio Valiante’s process is unusual to most and has been refined to achieve optimal performance."
    >
      <Results />
    </Layout>
  );
}

export default ProcessPage;
